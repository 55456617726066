.qr-reader {
    width: 430px;
    /* width: '100px'; */
    /* height: 100vh; */
    height: 520px;
    margin: 0 auto;
    position: relative;
  }
  
  .qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
  }
  
  .qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  /* Media Queries for mobile screens */
  @media (max-width: 426px) {
    .qr-reader {
      width: 100%;
    }
  }

.qr-decoded-text {
    /* display: block; */
    /* border: 1px dashed orange; */
    color: #333333;
    font-size: '3em';
    /* background-color: #00000088; */
    /* padding: 3px 5px; */
    /* position: absolute; */
    /* font-weight: bold; */
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    /* transform: translateX(-100%) translateY(-100%); */
}