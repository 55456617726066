body {
  /* max-width: 750px;
  padding: 0 5px;
  margin: auto !important; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gloBody {
  max-width: 750px !important;
  padding: 0 5px;
  margin: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1.glo {
  font-size: 72px;
}

.glo {
  background: -webkit-linear-gradient(#fb0, #ff1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

span.glo {
  background: -webkit-linear-gradient(#fb0, #ee5500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

footer {
  text-align: center;
}



.signedInInfo {
  float: right;
  text-align: right;
}



.batches div.row {
  border-bottom: 1px solid lightblue;
}
.batches div.row:hover {
  background-color: rgba(255, 187, 0, 0.185);
}

.batchRow {
  /* background-color: khaki; */
}

.videoRow {
  --bs-gutter-x: 0 !important;
}