.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

.tubeRect {
    outline: 3px solid white;
}
.tubeRect.predict-negative {
    outline-color: aquamarine;
    outline-style: dashed;
}

.tubeRect.predict-positive {
    outline-color: orange;
    outline-style: dashed;
}

.tubeRect.positive {
    outline-color: red;
}
.tubeRect.negative {
    outline-color: beige;
}

.tubeRect:hover {
    /* outline: 6px solid blue; */
    background-color: #FFFFFF66;
}

.tubeRectBottom:hover {
    background-color: #0000FF99;
}

.tubeRectRight:hover {
    background-color: #0000FF99;
}